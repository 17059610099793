import { createTheme } from '@material-ui/core/styles'

// line heights

const lineHeight = 24

// sizes
const rootFontSize = 16
const h1FontSize = 28
const body1FontSize = 16
const body2FontSize = 18
const subtitle1FontSize = 14

// weights
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

// colors
const black = '#3B4752'
const white = '#FFFFFF'
const body = '#3B4752'
const primary = '#0094FF'
const secondary = '#65B920'
const lightBlue = '#ECF2F6'

// fonts
const fontPrimary = 'Montserrat'

function lines(lines: number): string {
  return `${(lineHeight * lines) / rootFontSize}rem`
}

const identityIQTheme = createTheme({
  typography: {
    fontFamily: fontPrimary,
    htmlFontSize: rootFontSize,
    fontSize: rootFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightMedium,
      fontSize: `${h1FontSize / rootFontSize}rem`,
      lineHeight: lines(2),
      letterSpacing: 0,
    },
    h2: {
      fontWeight: 600,
      fontSize: '16px',
      letterSpacing: 0,
    },
    body1: {
      fontSize: body1FontSize,
      letterSpacing: 0,
    },
    body2: {
      fontSize: body2FontSize,
      letterSpacing: 0,
    },
    subtitle1: {
      // small text, disclosures
      fontSize: subtitle1FontSize,
    },
  },
  palette: {
    type: 'light',
    common: {
      black,
      white,
    },
    primary: {
      light: lightBlue,
      main: primary,
      contrastText: white,
    },
    error: {
      main: '#FE3B36',
    },
    secondary: {
      main: secondary,
      light: 'rgb(2, 174, 239)',
    },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
    text: {
      primary: body,
      secondary: black, // headings
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: 'normal',
        color: '#FFF',
        borderRadius: 8,
        padding: '8px 10px',
        backgroundColor: body,
      },
      arrow: {
        color: body,
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '100px',
        '&:hover': {
          backgroundColor: 'rgba(121,162,255,1)',
        },
        '&:disabled': {
          '& svg > g > g:nth-child(1) path': {
            fill: '#FFF',
          },
          '& svg > g > g:nth-child(2) path': {
            fill: '#FFF',
            opacity: 0.2,
          },
          opacity: 0.2,
          backgroundColor: primary,
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: fontWeightMedium,
        fontSize: body1FontSize,
      },
      underlineHover: {
        '&:hover': { textDecoration: 'none', color: '#7A7A7A' },
      },
    },
    MuiInputLabel: {
      root: {
        background: '#FFF',
        padding: '3px',
        margin: '-3px',
        color: body,
        fontWeight: 500,

        '&.MuiFormLabel-root.Mui-error': {
          color: '#FE3B36',
        },

        '&.Mui-focused': {
          color: body,
        },
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '4px',
        background: '#FFF',
        borderColor: body,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: body,
        },

        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FE3B36',
          borderWidth: '2px',
          '& span': {
            color: '#FFF !important',
          },
        },
      },
      input: {
        color: body,
        fontWeight: 500,
      },
    },
    MuiSelect: {
      icon: {
        background: 'url(./images/down.svg)',
        '& path': {
          display: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
        color: body,
      },
    },
  },
})

export type Theme = typeof identityIQTheme
export default identityIQTheme
