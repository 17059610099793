import { createTheme } from '@material-ui/core/styles'

// line heights

const lineHeight = 24

// sizes
const rootFontSize = 16
const h1FontSize = 28
const body1FontSize = 16
const body2FontSize = 18
const subtitle1FontSize = 14

// weights
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

// colors
const black = '#383838'
const white = '#FFFFFF'
const body = '#7A7A7A'
const primary = '#04ADD3'
const secondary = '#65B920'
const lightBlue = '#40C0DD17'

// fonts
const fontPrimary = 'Raleway'
const fontTitle = 'Rubik'

function lines(lines: number): string {
  return `${(lineHeight * lines) / rootFontSize}rem`
}

const myScoreIQTheme = createTheme({
  typography: {
    fontFamily: fontPrimary,
    htmlFontSize: rootFontSize,
    fontSize: rootFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightMedium,
      fontSize: `${h1FontSize / rootFontSize}rem`,
      fontFamily: fontTitle,
      lineHeight: lines(2),
      letterSpacing: 0,
    },
    h2: {
      fontWeight: 600,
      fontFamily: fontTitle,
      fontSize: '16px',
      lineHeight: lines(1),
      letterSpacing: 0,
    },
    body1: {
      fontSize: body1FontSize,
      letterSpacing: 0,
    },
    body2: {
      fontSize: body2FontSize,
      letterSpacing: 0,
    },
    subtitle1: {
      // small text, disclosures
      fontSize: subtitle1FontSize,
    },
  },
  palette: {
    type: 'light',
    common: {
      black,
      white,
    },
    primary: {
      light: lightBlue,
      main: primary,
      contrastText: white,
    },
    secondary: {
      main: secondary,
    },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
    text: {
      primary: body,
      secondary: black, // headings
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        lineHeight: '20px',
        color: '#383838',
        borderRadius: 8,
        padding: '12px 16px',
        backgroundColor: '#F4F6F7',
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
      },
      arrow: {
        color: '#F4F6F7',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: '100px',
        fontFamily: fontTitle,
        boxShadow: '0 3px 30px #40C0DD83',
        '&:hover': {
          backgroundColor: '#2abbdb',
        },
        '& svg > g > g:nth-child(1) path': {
          fill: '#FFF',
        },
        '& svg > g > g:nth-child(2) path': {
          fill: '#FFF',
          opacity: 0.2,
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: fontWeightMedium,
        fontSize: body1FontSize,
      },
      underlineHover: {
        '&:hover': { textDecoration: 'none', color: '#7A7A7A' },
      },
    },
    MuiInputLabel: {
      root: {
        background: '#FFF',
        padding: '3px',
        margin: '-3px',
        color: body,

        '&.Mui-focused': {
          color: body,
        },
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: '4px',
        background: '#FFF',
        borderColor: body,
      },
      input: {
        letterSpacing: '0.5px',
        color: black,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 500,
      },
    },
  },
})

export type Theme = typeof myScoreIQTheme
export default myScoreIQTheme
