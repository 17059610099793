import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkAPI } from '../types'
import useIDIQServices from '../idiqServices'
import { selectEnrollmentState, selectPlanState } from '@/state/selectors'
import {
  NetworkMessages,
  CNCStatusObject,
  NEW_LOGIN_URL,
  ENABLE_IDENTITY_SERVER,
  IDENTITY_SERVER_URL,
  WebsiteCode,
  LOGIN_SERVER_URL,
} from '@/constants'
import { updateUserStatus } from '@/state/actions/updateUserStatus'
import axios from 'axios'

interface Args {
  authStatus?: CNCStatusObject
  cuid?: string
  membershipNumber?: string
}

export const getMarketingProgram = (): string => {
  if (WebsiteCode === 'CSIQ') {
    return 'CREDITSCOREIQ'
  }

  if (WebsiteCode === 'CBIQ') {
    return 'CREDITBUILDERIQ'
  }

  return ''
}

const redirectRequest: AsyncThunkPayloadCreator<void, Args, ThunkAPI> = async (
  { authStatus, cuid, membershipNumber }: Args,
  { getState, dispatch, rejectWithValue },
) => {
  try {
    const enrollmentState = selectEnrollmentState(getState() as RootState)
    const planState = selectPlanState(getState() as RootState)
    const idiqServices = useIDIQServices()

    if (authStatus) {
      dispatch(updateUserStatus(authStatus))
    }

    cuid = cuid ?? enrollmentState.enrolledMember?.cuid
    membershipNumber = membershipNumber ?? enrollmentState.enrolledMember?.membershipNumber

    const response = await idiqServices.getRedirect(membershipNumber, {
      offerCode: planState.effortCode,
      planCode: planState.details?.planCode,
    })

    const authenticateWithIdentityServer: boolean = ENABLE_IDENTITY_SERVER === 'true'
    if (authenticateWithIdentityServer) {
      if (WebsiteCode === 'CSIQ' || WebsiteCode === 'CBIQ') {
        const url = new URL(response.data.redirectUrl)

        const tempLogin = await axios.post(`${LOGIN_SERVER_URL}/v1/login`, {
          username: enrollmentState.account.email || enrollmentState.enrolledMember?.email,
          password: enrollmentState.account.password || enrollmentState.enrolledMember?.password,
          marketingProgram: getMarketingProgram(),
        })

        // securityQuestionId - 9 is SSN question
        const { data } = await axios.post(
          `${LOGIN_SERVER_URL}/v2/security-question/validate`,
          {
            securityQuestionId: 9,
            securityAnswer: enrollmentState.identity.ssn?.slice(-4) || enrollmentState.enrolledMember?.ssn?.slice(-4),
            productType: getMarketingProgram(),
          },
          {
            headers: {
              'X-TempOAuthToken': tempLogin.data.tempSecurityQuestionToken,
            },
          },
        )

        const redirectUrl = await axios.post(
          `${LOGIN_SERVER_URL}/v1/login`,
          {
            username: enrollmentState.account.email || enrollmentState.enrolledMember?.email,
            password: enrollmentState.account.password || enrollmentState.enrolledMember?.password,
            marketingProgram: getMarketingProgram(),
          },
          {
            headers: {
              'X-SecurityQuestionToken': data.securityQuestionToken,
            },
          },
        )

        window.location.href =
          url.origin +
          url.pathname +
          '?token=' +
          redirectUrl.data.accessToken +
          '&securityToken=' +
          data.securityQuestionToken
      } else {
        const url = new URL(response.data.redirectUrl)
        const key = url.searchParams.get('Key')
        const redirectUrl = `${IDENTITY_SERVER_URL}/account/EnrollmentCookieAuth?return_url=${NEW_LOGIN_URL}&key=${key}&cuid=${cuid}`
        window.location.href = redirectUrl
      }
    } else {
      window.location.href = `${response.data.redirectUrl}&CUID=${cuid}`
    }
  } catch (error) {
    return rejectWithValue({ error: NetworkMessages.RedirectError })
  }
}

export const redirect = createAsyncThunk<void, Args, ThunkAPI>('user/redirect', redirectRequest)
