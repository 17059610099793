import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { AddOn, InterstitialAddOn, PostEnrollmentAddOn } from './types'

export interface AddOnsState {
  isInitialized: boolean
  items: AddOn[]
  interstitialAddOns: InterstitialAddOn[]
  postEnrollmentAddOn: PostEnrollmentAddOn | null
}

const initialState: AddOnsState = {
  isInitialized: false,
  items: [],
  interstitialAddOns: [],
  postEnrollmentAddOn: null,
}

const isInterstitialAddon = (key: string, benefits: Record<string, Record<string, string>>): boolean =>
  benefits[key]['TYPEID'] === '8' && benefits[key]['SHOWADDONINTERSTITIAL'] === 'true'

const getAddOns = (
  benefits: Record<string, Record<string, string>>,
  addOnParam: string,
  interstitialAddOnParam: string,
) => {
  if ((!addOnParam && !interstitialAddOnParam) || !benefits) {
    return []
  }

  const addOnCodes = addOnParam.split(',').map((code) => code.trim().toUpperCase())
  const interstitialAddOnCodes = interstitialAddOnParam.split(',').map((code) => code.trim().toUpperCase())

  return Object.keys(benefits).reduce((acc: AddOn[], key: string) => {
    const benefit = benefits[key]
    const code = benefit['CODE']

    if (benefit['TYPEID'] === '8' && (addOnCodes.includes(code) || interstitialAddOnCodes.includes(code))) {
      // only Subscription Add-Ons
      return [
        ...acc,
        {
          code,
          name: benefit['DESC'],
          price: parseFloat(benefit['FEE']),
          defaultPrice: parseFloat(benefit['DEFAULTPRICE']),
          itemHighlight: benefit['ITEMHIGHLIGHT'],
          longDesc: benefit['LONGDESC'],
        },
      ]
    }

    return acc
  }, [])
}

const getInterstitialAddOns = (benefits: Record<string, Record<string, string>>, interstitialAddOnParam: string) => {
  if (!benefits) {
    return []
  }

  const interstitialAddOnCodes = interstitialAddOnParam.split(',').map((code) => code.trim().toUpperCase())

  const getDisplayOrderByUrlParam = (code: string): number => {
    const position = interstitialAddOnCodes.indexOf(code)

    return position === -1 ? 10 : position - 10
  }

  return Object.keys(benefits)
    .filter((key) => isInterstitialAddon(key, benefits))
    .sort((a1, a2) => {
      const displayOrderA1 = getDisplayOrderByUrlParam(benefits[a1]['CODE']) + +(benefits[a1]['DISPLAYORDER'] || '11') // 10 is maximum, null should go last
      const displayOrderA2 = getDisplayOrderByUrlParam(benefits[a2]['CODE']) + +(benefits[a2]['DISPLAYORDER'] || '11')

      return displayOrderA1 - displayOrderA2
    })
    .slice(0, 3) // take first 3 addons
    .reduce((acc: InterstitialAddOn[], key: string) => {
      const benefit = benefits[key]

      return [
        ...acc,
        {
          code: benefit['CODE'],
          name: benefit['DESC'],
          price: parseFloat(benefit['FEE']),
          defaultPrice: parseFloat(benefit['DEFAULTPRICE']),
          itemHighlight: benefit['ITEMHIGHLIGHT'],
          longDesc: benefit['LONGDESC'],
        },
      ]
    }, [])
}

const getPostEnrollmentAddOn = (benefits: Record<string, Record<string, string>>) => {
  if (!benefits) {
    return null
  }

  const benefitKey = Object.keys(benefits)
    .filter((key) => isInterstitialAddon(key, benefits))
    .sort((a1, a2) => {
      const displayOrderA1: number = +(benefits[a1]['DISPLAYORDER'] || '11') // 10 is maximum, null should go last
      const displayOrderA2: number = +(benefits[a2]['DISPLAYORDER'] || '11')

      return displayOrderA1 - displayOrderA2
    })[0]

  if (benefitKey) {
    const benefit = benefits[benefitKey]

    return {
      code: benefit['CODE'],
      name: benefit['ALTDESC'] || benefit['DESC'],
      price: parseFloat(benefit['FEE']),
      oneTimePrice: parseFloat(benefit['ONETIMEFEE']),
      defaultPrice: parseFloat(benefit['DEFAULTPRICE']),
      itemHighlight: benefit['ITEMHIGHLIGHT'],
      interstitialHeaderStatement: benefit['INTERSTITIALHEADERSTMT'],
      longDesc: benefit['LONGDESC'],
    }
  }

  return null
}

const addOns: Slice = createSlice({
  name: 'addOns',
  initialState,
  reducers: {
    setAddOns: (
      state,
      action: PayloadAction<{
        benefits: Record<string, Record<string, string>>
        addOnCodes: string
        interstitialAddOnCodes: string
      }>,
    ) => {
      state.items = getAddOns(action.payload.benefits, action.payload.addOnCodes, action.payload.interstitialAddOnCodes)
      state.items = state.items.map((item: AddOn) => ({ ...item, isSelected: true }))

      // No need to show postenrollment popup if addon has already been added to the cart once
      if (
        state.postEnrollmentAddOn &&
        state.items &&
        state.items.find((item: AddOn) => item.code === state.postEnrollmentAddOn.code)
      ) {
        state.postEnrollmentAddOn = null
      }

      state.isInitialized = true
    },
    setInterstitialAddOns: (
      state,
      action: PayloadAction<{ benefits: Record<string, Record<string, string>>; interstitialAddOnCodes: string }>,
    ) => {
      state.interstitialAddOns = getInterstitialAddOns(action.payload.benefits, action.payload.interstitialAddOnCodes)
    },
    setPostEnrollmentAddOn: (state, action: PayloadAction<{ benefits: Record<string, Record<string, string>> }>) => {
      state.postEnrollmentAddOn = getPostEnrollmentAddOn(action.payload.benefits)
    },
    removeAddOn: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter((addon: AddOn) => addon.code !== action.payload)
    },
    toggleAddOn: (state, action: PayloadAction<string>) => {
      const foundItem = state.items.find((addon: AddOn) => addon.code === action.payload)
      if (foundItem) {
        foundItem.isSelected = !foundItem.isSelected
      } else {
        const addOn = state.interstitialAddOns.find((addon: InterstitialAddOn) => addon.code === action.payload)
        addOn.isSelected = true
        state.items = [...state.items, addOn]

        // No need to show postenrollment popup if addon has already been added to the cart once
        if (state.postEnrollmentAddOn && state.postEnrollmentAddOn.code === action.payload) {
          state.postEnrollmentAddOn = null
        }
      }
    },
    addAddOn: (state, action: PayloadAction<{ benefits: Record<string, Record<string, string>>; code: string }>) => {
      if (!action.payload.code || !action.payload.benefits) {
        return
      }

      const benefitKey = Object.keys(action.payload.benefits).find(
        (key: string) => action.payload.benefits[key]['CODE'] === action.payload.code,
      )

      if (!benefitKey) {
        return
      }

      const benefit = action.payload.benefits[benefitKey]
      const addOn = {
        code: action.payload.code,
        name: benefit['DESC'],
        price: parseFloat(benefit['FEE']),
        defaultPrice: parseFloat(benefit['DEFAULTPRICE']),
        itemHighlight: benefit['ITEMHIGHLIGHT'],
        longDesc: benefit['LONGDESC'],
        isSelected: true,
      }
      state.items = [...state.items, addOn]
    },
  },
})

export const { setAddOns, setInterstitialAddOns, setPostEnrollmentAddOn, removeAddOn, toggleAddOn, addAddOn } =
  addOns.actions
export default addOns.reducer
