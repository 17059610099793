/* a little magic at the very bottom of the file */

export const Images = {
  HeaderLogo: process.env.HEADER_LOGO,
  EquifaxLogo: './images/equifax.svg',
  ExperianLogo: './images/experian.svg',
  TransunionLogo: './images/transunion.svg',
  SubmitButtonIcon: './images/icon_submit_button_arrow.svg',
  BetterBusinessBureauLogo: './images/csiq/bbbLogoDark.webp',
  TrustwaveSeal: './images/csiq/sslLogoDark.webp',
  LockPrivacySecurityLogo: './images/icon_lock.svg',
  SecureTrustLogo: './images/logo_secure_trust.svg',
  GreenCheckmarkIcon: './images/csiq/icon_green_checkmark.svg',
  InstallmentLoansIcon: './images/icon_installment_loans.svg',
  RevolvingAccountsIcon: './images/icon_revolving_accounts.svg',
  RealEstateAccountsIcon: './images/icon_real_estate_accounts.svg',
  OtherAccountsIcon: './images/icon_other_accounts.svg',
  WhiteShieldIcon: './images/icon_white_shield.svg',
  BlackCheckmarkIcon: './images/icon_black_checkmark.svg',
  CalendarIcon: './images/icon_calendar.svg',
  OpenAccounts: './images/icon_open_accounts.svg',
  HardInquiries: './images/icon_hard_inquiries.svg',
  NegativeAccounts: './images/icon_negative_accounts.svg',
  CreditUtilization: './images/icon_credit_utilization.svg',
  PlanDetailsIcon: './images/Success-Outline.svg',
  DiscountSuccessIcon: './images/icon_plan_details.svg',
  EditIcon: './images/edit.svg',
  CreditCardIcons: './images/billing_card_logos.svg',
  OkGreenShield: './images/ok_green_shield.svg',
  Fingerprint: './images/fingerprint.svg',
  CreditCardBack: './images/credit_card_back.svg',
  VisaCardIcon: './images/Visa_Blue.png',
  InfoIcon: './images/info_icon.svg',
  IconDetails: './images/icon_i.svg',
  IconHelp: './images/icon_i.svg',
  IconCoupon: './images/coupon.svg',
  ErrorMark: './images/error-mark.svg',
}
